import { createFromIconfontCN } from '@ant-design/icons'
import moment from 'moment'

export const Global = {
  user: {} as any,
  role: {
    //项目数量
    projectNum: 8,
    //技术方案数
    technicalNum: 10,
    //项目投资数量
    financeProjectInvestNum: 6,
    //运营成本
    financeOperationCostNum: 6,
    //财务成本
    financeCostNum: 6,
    //激励补贴
    financeAwardSubsidyNum: 6,
    //财务情景
    financeParamNum: 6,
    //财务方案选择技术方案数量
    financeSelectTechnicalNum: 6,
    //财务方案
    financeNum: 36,
    //并行计算的技术方案数量
    syncCalcTechnicalNum: 5,
    //时序计算最大点数
    timingCalcMaxPoints: 8760,
  },
  emailTipVisible: false,
  updateUser: <any>[],
  changeMenu: <any>[],
  invite: null,
  projectAuth: null,
  projectDataAuth: {} as any,
}

export default Global

export const CustomIcons = createFromIconfontCN({
  scriptUrl: '//at.alicdn.com/t/font_2992477_5uwn6pu09up.js',
})

export const getFixedNumber = (val: number, suffix: number) => {
  if (!val && val !== 0) {
    return '0'
  }
  return parseFloat(val.toFixed(suffix))
}

export const ChartColorMaps = [
  {
    line: 'rgba(30, 198, 146, 1)',
    area: ['rgba(30, 198, 146, 0.08)', 'rgba(30, 198, 146, 0)'],
  },
  {
    line: 'rgba(50, 116, 246, 1)',
    area: ['rgba(50, 116, 246, 0.08)', 'rgba(50, 116, 246, 0)'],
  },
  {
    line: 'rgba(246, 185, 50, 1)',
    area: ['rgba(246, 185, 50, 0.08)', 'rgba(246, 185, 50, 0)'],
  },
]

export const validate7Days = (_: any, value: any) => {
  if (!value || value.length < 2) {
    return Promise.resolve()
  }
  const [start, end] = value as moment.Moment[]
  if (start.diff(end, 'days') > -7) {
    return Promise.reject(new Error(_.message))
  } else {
    return Promise.resolve()
  }
}


export const showRegister = !!localStorage.getItem('show-register') || false

export const MAX_ATTACHMENT_COUNT = 10;
