import {useNavigate, useParams} from 'react-router-dom'
import {useEffect, useRef, useState} from 'react'
import {Button, Card, Col, Form, Input, message, Modal, Row, Tooltip, Upload} from 'antd'
import {backButtonSvg} from './work-order-home'
import moment from 'moment'
import {UploadOutlined} from '@ant-design/icons'
import {WorkOrderService} from '../../../apis/manage-service'
import {CustomIcons, MAX_ATTACHMENT_COUNT} from '../../../global'


const getColorWithName = (name = '') => {
  const ICON_COLORS = [
    '#1EC692',
    '#3274F6',
    '#F97942',
    '#B532F6',
    '#F6B932',
    '#54A378',
    '#F56C6C',
    '#3EB1B7',
    '#8468B2',
    '#B9A43F'
  ]
  const num = name.split('').reduce((sum, i) => sum + i.charCodeAt(0), 0)
  const idx = Math.floor(num) % 10
  return {color: 'white', bgColor: ICON_COLORS[idx]}
}
export const WorkOrderDetail = () => {
  const params = useParams()
  const navigate = useNavigate()
  const {orderNo} = params
  const [page, setPage] = useState(0)
  const [showMore, setShowMore] = useState(false)
  const uploadRef = useRef()
  const size = '100'

  const content = useRef<HTMLInputElement>()

  const [form] = Form.useForm()

  const [data, setData] = useState<any>()
  const [messageList, setMessageList] = useState<any[]>([])

  const [attachmentList, setAttachmentList] = useState<any[]>([])

  const props = {
    action: '',
    maxCount: MAX_ATTACHMENT_COUNT,
    multiple: true,
    customRequest: (item: any) => {
      setTimeout(() => {
        const {file, onError, onSuccess} = item
        const size = file.size
        if (size > 10 * 1024 * 1024) {
          onError('File Too Large', '文件大小超过10M')
          return
        }
        const idx = attachmentList.length
        if (idx >= MAX_ATTACHMENT_COUNT) {
          message.error(`最多上传${MAX_ATTACHMENT_COUNT}个文件`).then()
          return
        }
        attachmentList[idx] = {file}
        WorkOrderService.postManageWorkOrderUploadFile({
          file
        }).then((resp) => {
          file.url = resp.data.url
          attachmentList[idx] = {...resp.data, file}
          onSuccess()
        }).catch(() => {
          attachmentList.splice(idx, 1)
          onError()
        })
      })
    },
    onChange({file, fileList}) {
      if (file.status !== 'uploading') {
        if (!fileList.includes(file)) {
          const idx = attachmentList.findIndex(o => o.file.uid === file.uid)
          if (idx >= 0) {
            attachmentList.splice(idx, 1)
          }
        }
      }
    },
    defaultFileList: [],
    showUploadList: {
      showDownloadIcon: false,
      showRemoveIcon: true,
      removeIcon: <CustomIcons type='klf-delete'/>
    },
    itemRender: (originNode) => {
      return originNode
    }
  }

  useEffect(() => {
    loadInfo()
    loadMessageList(0, true)
  }, [])

  const loadInfo = () => {
    WorkOrderService.getManageWorkOrderInfo({
      orderNo
    }).then(({data: resp}) => {
      setData(resp)
    })
  }

  const loadMessageList = (pageNumber = page, autoFocus = false) => {
    WorkOrderService.getManageWorkOrderMsgList({
      orderNo,
      page: (pageNumber + 1).toString(),
      size
    }).then(({data: resp}) => {
      setPage(resp.page)
      if (pageNumber === 0) {
        setMessageList(resp.data)
        if (resp.total > resp.data.length) {
          setShowMore(true)
        } else {
          setShowMore(false)
        }
      } else {
        setMessageList((list) => {
          const messageList = [...list, ...resp.data]
          if (resp.total > messageList.length) {
            setShowMore(true)
          } else {
            setShowMore(false)
          }
          return messageList
        })
      }

      if (autoFocus) {
        setTimeout(() => {
          content.current.focus()
        }, 100)
      }

    })
  }

  return (
    <div>
      <Card className='card-content'>
        <Button type='text' className='back-button' onClick={() => {
          navigate('../')
        }}>{backButtonSvg}工单详情</Button>
        <Row className='detail-info' style={{
          fontSize: 16
        }}>
          <Col span={24}>
            <Row style={{
              fontSize: 16
            }}>
              <Col span={24} style={{
                fontWeight: 500,
                marginBottom: '.5rem'
              }}>
                创建人信息
              </Col>
              <Col style={{
                color: '#767F95', width: '80px'
              }}>账号</Col>
              <Col style={{
                color: '#262E3F'
              }}>{data?.userName}</Col>
              <Col style={{
                marginLeft: 120,
                color: '#767F95', width: '80px'
              }}>电话</Col>
              <Col style={{
                color: '#262E3F'
              }}>{data?.telephone}</Col>
              <Col style={{
                marginLeft: 120,
                color: '#767F95', width: '80px'
              }}>邮箱</Col>
              <Col style={{
                color: '#262E3F'
              }}>{data?.email}</Col>
            </Row>
          </Col>
          <Col span={8}>
            <Row>
              <Col style={{
                color: '#767F95', width: '80px'
              }}>主题描述</Col>
              <Col span={18} style={{
                color: '#262E3F'
              }}>{data?.orderDesc}</Col>
            </Row>
          </Col>
          <Col span={8}>
            <Row>
              <Col style={{
                color: '#767F95', width: '80px'
              }}>所属组织</Col>
              <Col span={18} style={{
                color: '#262E3F'
              }}>{data?.companyName}</Col>
            </Row>
          </Col>
          <Col span={8}>
            <Row>
              <Col style={{
                color: '#767F95', width: '80px'
              }}>需求类型</Col>
              <Col span={18} style={{
                color: '#262E3F'
              }}>{{
                1: '业务咨询',
                2: '使用帮助',
                3: '问题反馈',
                4: '其他'
              }[data?.type]}</Col>
            </Row>
          </Col>
          <Col span={24}>
            <Row style={{
              flexWrap: 'nowrap'
            }}>
              <Col style={{
                color: '#767F95', width: '80px',
                flexShrink: 0
              }}>需求内容</Col>
              <Col style={{
                color: '#262E3F'
                // textOverflow: 'ellipsis',
                // overflow: 'hidden',
                // whiteSpace: 'nowrap'
              }}>{data?.orderContent}</Col>
            </Row>
          </Col>
          <Col span={24}>
            <Row>
              <Col style={{
                color: '#767F95', width: '80px'
              }}>发起时间</Col>
              <Col span={18} style={{
                color: '#262E3F'
              }}>{data?.createTime && moment(data?.createTime).format('YYYY-MM-DD HH:mm')}</Col>
            </Row>
          </Col>
          {
            data?.fileList?.length > 0 && <Col span={24}>
              <Row>
                <Col style={{
                  color: '#767F95', width: '80px'
                }}>附件</Col>
                <Col className='attachment-list' span={18} style={{
                  color: '#262E3F'
                }}>
                  {data?.fileList?.map((o) => {
                    return <Tooltip
                      key={o.url}
                      overlay={o.name}
                    >
                      <Button className='attachment-item' onClick={() => {
                        if (['png', 'jpg', 'jpeg', 'gif', 'svg', 'bmp'].includes(o.suffix)) {
                          Modal.confirm({
                            icon: null,
                            content: <img style={{maxWidth: 820}} src={o.url} alt=''/>,
                            okText: '关闭',
                            cancelText: '下载',
                            width: 900,
                            onCancel: () => {
                              window.open(o.url, '_blank')
                            }
                          })
                        } else {
                          window.open(o.url, '_blank')
                        }
                      }} key={o.url}>{o.name}</Button>
                    </Tooltip>
                  })}
                </Col>
              </Row>
            </Col>
          }

        </Row>
        <div style={{textAlign: 'center', marginTop: 9}}>
          {
            showMore && <div style={{textAlign: 'center', marginTop: 9}}>
              <Button type='text' onClick={() => {
                loadMessageList()
              }}>加载更多记录</Button>
            </div>
          }
        </div>
        <div style={{
          flexGrow: 1,
          overflowY: 'auto',
          paddingRight: '1rem',
          paddingTop: '.5rem',
          paddingBottom: '.5rem'
        }}>
          {
            [...messageList].reverse().map((o, idx) => (
              <div className={`chat-body ${o.type !== 0 ? 'body-revert' : ''}`} key={idx}>
                <div className='chat-row'>
                  {
                    o.name !== 'Admin' ? <div className='chat-icon' style={{
                      backgroundColor: !o.headUrl && getColorWithName(o.name).bgColor,
                      color: !o.headUrl && getColorWithName(o.name).color,
                      overflow: "hidden",
                    }}>
                      {o.headUrl ? <img style={{
                        width: '100%',
                        height: '100%'
                      }} src={o.headUrl} alt={o.name}/> : o.name?.charAt(0)?.toUpperCase()}
                    </div> : <div className='chat-icon'><img style={{
                      width: 32,
                      height: 32
                    }} src='logo.png' alt='admin'/></div>
                  }
                  <div className='chat-content'>
                    <div className='chat-time'>{
                      moment(o.createTime).format('YYYY-MM-DD HH:mm')
                    }</div>
                    <div className='chat-text'>{
                      o.orderContent
                    }</div>
                    {
                      o?.fileList?.length > 0 && <div className='chat-attachment'>
                        <Row>
                          <Col className={`attachment-list ${o.type === 1 ? 'list-revert' : ''}`} span={24} style={{
                            color: '#262E3F'
                          }}>
                            {o?.fileList?.map((o) => {
                              return <Tooltip
                                key={o.url}
                                overlay={o.name}
                              >
                                <Button className='attachment-item' onClick={() => {
                                  if (['png', 'jpg', 'jpeg', 'gif', 'svg', 'bmp'].includes(o.suffix)) {
                                    Modal.confirm({
                                      icon: null,
                                      content: <img style={{maxWidth: 820}} src={o.url} alt=''/>,
                                      okText: '关闭',
                                      cancelText: '下载',
                                      width: 900,
                                      onCancel: () => {
                                        window.open(o.url, '_blank')
                                      }
                                    })
                                  } else {
                                    window.open(o.url, '_blank')
                                  }
                                }} key={o.url}>{o.name}</Button>
                              </Tooltip>
                            })}
                          </Col>
                        </Row>
                      </div>
                    }
                  </div>
                </div>
              </div>
            ))
          }
        </div>
        <div className='border-top' style={{
          paddingTop: '1rem'
        }}>
          <Form form={form}>
            <Form.Item
              label='回复'
              name='orderContent'
              rules={[
                {
                  validator: (rule, val, callback) => {
                    if (!val) {
                      callback();
                    }
                    let validateResult = !!val.trim();
                    if (!validateResult) {
                      callback('请输入回复信息');
                    }
                    callback();
                  }
                },
                {
                  required: true,
                  message: '请输入回复信息'
                }
              ]}>
              <Input.TextArea ref={content} maxLength={800} style={{
                maxHeight: 200
              }}/>
            </Form.Item>
            <Form.Item label='附件'>
              <Upload ref={uploadRef} {...props} onPreview={(file) => {
                if (file.status === 'success' || file.status === 'done') {
                  if (file.type.includes(`image`)) {
                    Modal.confirm({
                      icon: null,
                      content: <img style={{maxWidth: 820}} src={file.url} alt=''/>,
                      okText: '关闭',
                      cancelText: '下载',
                      width: 900,
                      onCancel: () => {
                        window.open(file.url, '_blank')
                      }
                    })
                  } else {
                    window.open(file.url, '_blank')
                  }
                }
              }}>
                <Button type='link' icon={<UploadOutlined/>}>上传附件</Button>
              </Upload>
            </Form.Item>
            <Form.Item style={{
              marginBottom: 0
            }}>
              <Button type='primary' onClick={() => {
                form.validateFields().then(() => {
                  const orderContent = form.getFieldValue('orderContent')
                  WorkOrderService.postManageWorkOrderReply({
                    root: {
                      orderNo,
                      orderContent,
                      fileUrls: attachmentList.filter(o => !!o.url).map(o => ({
                        url: o.url,
                        name: o.name,
                        suffix: o.suffix
                      }))
                    }
                  }).then(() => {
                    if (uploadRef?.current) {
                      (uploadRef.current as any).fileList.length = 0
                    }
                    setAttachmentList([])
                    form.setFieldsValue({
                      orderContent: ''
                    })
                    loadMessageList(0)
                  })
                })
              }}>发送</Button>
            </Form.Item>
          </Form>
        </div>
      </Card>
    </div>
  )
}
