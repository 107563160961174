import React, { ReactElement } from 'react'
import Global from '../../global'

export interface ILogoProps {
  image?: string
  text?: string | ReactElement
  url?: string
  size?: number
  cantJump?: boolean
}

export default function Logo({ size = 30, image = '/logo.png', text = 'i3060', url, cantJump = false }: ILogoProps) {
  const jumpTo = () => {
    location.assign(url || '#/home')
    Global.changeMenu.forEach((Fn: any) => {
      Fn(url || '#/home')
    })
  }

  return (
    <div className="logo">
      <div
        onClick={() => {
          !cantJump && jumpTo()
        }}
        style={{ display: 'flex', alignItems: 'center' }}
      >
        {image && <img src={image} style={{ height: size }} alt="logo" />}
        <span style={{ color: 'black', fontWeight: 700, marginLeft: '.5em' }}>{text}</span>
      </div>
    </div>
  )
}
