import { Tabs } from 'antd'
import { useEffect, useState } from 'react'
import AccountLogin from './account-login'
import styles from '../index.module.scss'

// 登录组件
function LoginPage() {
  const [activeTab, setActiveTab] = useState('account')

  useEffect(() => {})

  return (
    <div style={{ paddingBottom: 50 }}>
      <Tabs
        activeKey={activeTab}
        onChange={(e) => {
          setActiveTab(e)
        }}
        style={{ overflow: 'visible' }}
        className={styles.loginTabs}
      >
        <Tabs.TabPane tab="账号登录" key="account">
          <AccountLogin />
        </Tabs.TabPane>
      </Tabs>
    </div>
  )
}

export default LoginPage
