import { Navigate } from 'react-router-dom'
import { WorkOrderHome } from './pages/work-order/home/work-order-home'
import { WorkOrderList } from './pages/work-order/home/list'
import { WorkOrderDetail } from './pages/work-order/home/detail'
import Login from './pages/login'
import LoginPage from './pages/login/components/login'

export const workOrderRouter = [
  {
    path: 'login',
    element: <Login />,
    breadcrumbName: '',
    children: [
      {
        path: '/login',
        element: <Navigate replace to="index" />,
      },
      {
        path: 'index',
        element: <LoginPage />,
      },
    ],
  },
  {
    path: '/',
    element: <WorkOrderHome />,
    children: [
      {
        path: '',
        element: <Navigate replace to="list" />,
      },
      {
        path: 'work-order',
        element: <Navigate replace to="/list" />,
      },
      {
        path: 'home',
        element: <Navigate replace to="/list" />,
      },
      {
        path: 'list',
        element: <WorkOrderList />,
      },
      {
        path: 'detail/:orderNo',
        element: <WorkOrderDetail />,
      },
    ],
  },
]
