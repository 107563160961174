import { Button, Form, Input, message } from 'antd'
import { useState } from 'react'
import { token } from '../../../utils/utils'
import { useNavigate } from 'react-router-dom'
import _ from 'lodash'
import { LoginService } from '../../../apis/manage-service'

// 用户密码登录组件
function AccountLogin() {
  const [form] = Form.useForm()
  const navigate = useNavigate()
  const [loading, setLoading] = useState(false)
  const [loginInfo, setLoginInfo] = useState({
    accountName: '',
    password: ''
  })

  // 登录
  const handleLogin = _.throttle(
    (values: any) => {
      if (loading) return
      setLoading(true)
      LoginService.postManageLogin({
        root: {
          ...values,
          // password: sm2.doEncrypt(values.password, SM2CODE, 0),
        }
      })
        .then((res) => {
          if (res.code === 200) {
            console.log(res)
            token.set(res?.data?.token)
            message.success('登录成功')
            //
            // UserService.info()
            //   .then((res) => {
            //     Global.user = res?.data || {}
            //     if (!res?.data?.emailCheck) {
            //       Global.emailTipVisible = true
            //     }
            //     UserService.roleInfo().then((resp) => {
            //       Global.role = resp.data
            navigate('/')
            //     })
            //   })
            //   .catch(() => {
            //     Global.user = {}
            //   })
          } else {
            message.error(res.msg)
          }
        })
        .catch(() => {
          setLoading(false)
        })
        .finally(() => {
        })
    },
    2000,
    {
      leading: true,
      trailing: false
    }
  )

  return (
    <div>
      <div>
        <Form
          labelAlign='left'
          onFinish={(value) => {
            !loading && handleLogin(value)
          }}
          validateTrigger='onBlur'
          form={form}
          style={{
            width: '100%'
          }}
        >
          <Form.Item
            required
            name='accountName'
          >
            <Input
              size='large'
              placeholder='请输入手机号/邮箱'
              onChange={(e) => {
                setLoginInfo({
                  ...loginInfo,
                  accountName: e.target.value
                })
              }}
            />
          </Form.Item>
          <Form.Item
            name='password'
            rules={[
              {
                required: true,
                message: '密码必填'
              },
            ]}
          >
            <Input
              size='large'
              type='password'
              placeholder='请输入您的密码'
              onChange={(e) => {
                setLoginInfo({
                  ...loginInfo,
                  password: e.target.value
                })
              }}
            />
          </Form.Item>
          <Form.Item>
            <Button
              size='large'
              style={{
                width: '100%',
                height: 42,
                marginTop: '40px'
              }}
              type='primary'
              htmlType='submit'
              loading={loading}
            >
              登录
            </Button>
          </Form.Item>
        </Form>
      </div>
    </div>
  )
}

export default AccountLogin
