import {Button, Card, Form, Input, Radio, Space, Table, Checkbox, Pagination} from 'antd'
import { useNavigate } from 'react-router-dom'
import React, { useEffect, useState } from 'react'
import { CustomIcons } from '../../../global'
import styles from '../index.module.scss'
import moment from 'moment'
import { WorkOrderService } from '../../../apis/manage-service'

const query = {
  total: 0,
  page: 1,
  size: 20,

  orderNo: '',
  orderDesc: '',
  userName: '',
  respondStatus: '',
  type: '1,2,3,4',

  createTimeSort: '',
  updateTimeSort: ''
} as any

export const WorkOrderList = () => {
  const navigate = useNavigate()

  const [dataSource, setDataSource] = useState([])

  useEffect(() => {
    loadData()
  }, [])

  const [nameFilter, setNameFilter] = useState('')
  const [descFilter, setDescFilter] = useState('')
  const [userNameFilter, setUserNameFilter] = useState('')

  const [form] = Form.useForm()

  const loadData = () => {
    WorkOrderService.getManageWorkOrderList(query).then(({ data: resp }) => {
      query.total = resp.total
      query.page = resp.page
      query.size = resp.size
      setDataSource(resp.data)
    })
  }

  const columns = [
    {
      title: <strong>工单编号</strong>,
      key: 'orderNo',
      dataIndex: 'orderNo',
      width: 380,
      fixed: 'left' as any,
      onFilterDropdownVisibleChange: (visible: boolean) => {
        if (!visible) {
          setNameFilter(query.orderNo)
        }
      },
      filterIcon: (
        <CustomIcons
          type='klf-search'
          style={{
            color: query.orderNo ? '#1ec692' : '#bfbfbf'
          }}
        />
      ),
      filterDropdown: ({ confirm, visible }: any) => {
        if (!visible) {
          ;(window as any).closeDropdown = null
          return null
        }
        setTimeout(() => {
          ;(window as any).closeDropdown = confirm
        })
        return (
          <div
            style={{
              padding: '.5rem 1rem'
            }}
          >
            <Input
              id='nameFilter'
              value={nameFilter}
              placeholder='请输入工单编号'
              onChange={(dom) => {
                setNameFilter(dom.target.value)
              }}
              onBlur={(dom) => {
                setNameFilter(dom.target.value)
              }}
              onPressEnter={() => {
                query.orderNo = nameFilter
                query.page = 1
                loadData()
                confirm?.()
              }}
            />
            <div
              style={{
                marginTop: '.5rem',
                textAlign: 'right'
              }}
            >
              <Button
                onClick={() => {
                  setNameFilter('')
                }}
              >
                重置
              </Button>
              <Button
                type='primary'
                style={{
                  marginLeft: '.5rem'
                }}
                onClick={() => {
                  query.orderNo = nameFilter
                  query.page = 1
                  loadData()
                  confirm?.()
                }}
              >
                确定
              </Button>
            </div>
          </div>
        )
      },
      render: (text: string, row: any) => {
        return <Button type='link' onClick={() => {
          navigate(`../detail/${row.orderNo}`)
        }}>{text}</Button>
      }
    },
    {
      title: <strong>主题描述</strong>,
      key: 'orderDesc',
      dataIndex: 'orderDesc',
      width: 300,
      onFilterDropdownVisibleChange: (visible: boolean) => {
        if (!visible) {
          setDescFilter(query.orderDesc)
        }
      },
      filterIcon: (
        <CustomIcons
          type='klf-search'
          style={{
            color: query.orderDesc ? '#1ec692' : '#bfbfbf'
          }}
        />
      ),
      filterDropdown: ({ confirm, visible }: any) => {
        if (!visible) {
          ;(window as any).closeDropdown = null
          return null
        }
        setTimeout(() => {
          ;(window as any).closeDropdown = confirm
        })
        return (
          <div
            style={{
              padding: '.5rem 1rem'
            }}
          >
            <Input
              id='descFilter'
              value={descFilter}
              placeholder='请输入主题描述'
              onChange={(dom) => {
                setDescFilter(dom.target.value)
              }}
              onBlur={(dom) => {
                setDescFilter(dom.target.value)
              }}
              onPressEnter={() => {
                query.orderDesc = descFilter
                query.page = 1
                loadData()
                confirm?.()
              }}
            />
            <div
              style={{
                marginTop: '.5rem',
                textAlign: 'right'
              }}
            >
              <Button
                onClick={() => {
                  setDescFilter('')
                }}
              >
                重置
              </Button>
              <Button
                type='primary'
                style={{
                  marginLeft: '.5rem'
                }}
                onClick={() => {
                  query.orderDesc = descFilter
                  query.page = 1
                  loadData()
                  confirm?.()
                }}
              >
                确定
              </Button>
            </div>
          </div>
        )
      }
    },
    {
      title: <strong>需求类型</strong>,
      key: 'type',
      dataIndex: 'type',
      width: 150,
      render: (text: string) => {
        return {
          '1': '业务咨询',
          '2': '使用帮助',
          '3': '问题反馈',
          '4': '其他'
        }[text.toString()]
      },
      filterIcon: <CustomIcons type='klf-filter' />,
      onFilterDropdownVisibleChange: (visible: boolean) => {
        if (!visible) {
          form.setFieldsValue({ type: query.type.split(',') })
        }
      },
      filterDropdown: ({ confirm, visible, filters }: any) => {
        if (!visible) {
          ;(window as any).closeDropdown = null
        } else {
          setTimeout(() => {
            ;(window as any).closeDropdown = confirm
          })
        }
        return (
          <div className={styles.customFilter}>
            <Form
              onFinish={() => {
                query.type = form.getFieldValue('type').join(',')
                query.page = 1
                loadData()
                confirm()
              }}
              {...{
                labelCol: { span: 24 },
                wrapperCol: { span: 24 }
              }}
              colon={false}
              form={form}
            >
              <Form.Item
                className='label-low'
                name={'type'}
                label={'需求类型'}
                style={{
                  alignItems: 'center'
                }}
              >
                <Checkbox.Group>
                  <Space direction='vertical'>
                    {filters.map((filter: any) => (
                      <Checkbox key={filter.value} value={filter.value}>
                        {filter.text}
                      </Checkbox>
                    ))}
                  </Space>
                </Checkbox.Group>
              </Form.Item>
              <Form.Item className='filter-btns'>
                <div
                  style={{
                    marginTop: '.5rem',
                    textAlign: 'right'
                  }}
                >
                  <Button
                    onClick={() => {
                      form.setFieldsValue({
                        type: [1, 2, 3, 4]
                      })
                    }}
                  >
                    重置
                  </Button>
                  <Button
                    type='primary'
                    style={{
                      marginLeft: '.5rem'
                    }}
                    htmlType='submit'
                  >
                    确定
                  </Button>
                </div>
              </Form.Item>
            </Form>
          </div>
        )
      },
      filters: [
        { text: '业务咨询', value: '1' },
        { text: '使用帮助', value: '2' },
        { text: '问题反馈', value: '3' },
        { text: '其他', value: '4' }
      ]
    },
    {
      title: <strong>状态</strong>,
      key: 'respondStatus',
      dataIndex: 'respondStatus',
      width: 100,
      filterIcon: <CustomIcons type='klf-filter' />,
      onFilterDropdownVisibleChange: (visible: boolean) => {
        if (!visible) {
          form.setFieldsValue({ respondStatus: query.respondStatus })
        }
      },
      filterDropdown: ({ confirm, visible, filters }: any) => {
        if (!visible) {
          ;(window as any).closeDropdown = null
        } else {
          setTimeout(() => {
            ;(window as any).closeDropdown = confirm
          })
        }
        return (
          <div className={styles.customFilter}>
            <Form
              onFinish={() => {
                query.respondStatus = form.getFieldValue('respondStatus')
                query.page = 1
                loadData()
                confirm()
              }}
              {...{
                labelCol: { span: 24 },
                wrapperCol: { span: 24 }
              }}
              colon={false}
              form={form}
            >
              <Form.Item
                className='label-low'
                name={'respondStatus'}
                label={'状态'}
                style={{
                  alignItems: 'center'
                }}
              >
                <Radio.Group>
                  <Space direction='vertical'>
                    {filters.map((filter: any) => (
                      <Radio key={filter.value} value={filter.value}>
                        {filter.text}
                      </Radio>
                    ))}
                  </Space>
                </Radio.Group>
              </Form.Item>
              <Form.Item className='filter-btns'>
                <div
                  style={{
                    marginTop: '.5rem',
                    textAlign: 'right'
                  }}
                >
                  <Button
                    onClick={() => {
                      form.setFieldsValue({
                        respondStatus: undefined
                      })
                    }}
                  >
                    重置
                  </Button>
                  <Button
                    type='primary'
                    style={{
                      marginLeft: '.5rem'
                    }}
                    htmlType='submit'
                  >
                    确定
                  </Button>
                </div>
              </Form.Item>
            </Form>
          </div>
        )
      },
      filters: [
        { text: '待回复', value: '0' },
        { text: '已回复', value: '1' }
      ],
      render: (text: any) => {
        if (text.toString() === '0') {
          return <div className='status-with-badge'>
            <div className='badge error-badge' />
            待回复</div>
        } else {
          return <div className='status-with-badge'>
            <div className='badge success-badge' />
            已回复</div>
        }
      }
    },
    {
      title: <strong>创建人</strong>,
      key: 'userName',
      dataIndex: 'userName',
      width: 150,
      onFilterDropdownVisibleChange: (visible: boolean) => {
        if (!visible) {
          setUserNameFilter(query.userName)
        }
      },
      filterIcon: (
        <CustomIcons
          type='klf-search'
          style={{
            color: query.userName ? '#1ec692' : '#bfbfbf'
          }}
        />
      ),
      filterDropdown: ({ confirm, visible }: any) => {
        if (!visible) {
          ;(window as any).closeDropdown = null
          return null
        }
        setTimeout(() => {
          ;(window as any).closeDropdown = confirm
        })
        return (
          <div
            style={{
              padding: '.5rem 1rem'
            }}
          >
            <Input
              id='userNameFilter'
              value={userNameFilter}
              placeholder='请输入创建人'
              onChange={(dom) => {
                setUserNameFilter(dom.target.value)
              }}
              onBlur={(dom) => {
                setUserNameFilter(dom.target.value)
              }}
              onPressEnter={() => {
                query.userName = userNameFilter
                query.page = 1
                loadData()
                confirm?.()
              }}
            />
            <div
              style={{
                marginTop: '.5rem',
                textAlign: 'right'
              }}
            >
              <Button
                onClick={() => {
                  setUserNameFilter('')
                }}
              >
                重置
              </Button>
              <Button
                type='primary'
                style={{
                  marginLeft: '.5rem'
                }}
                onClick={() => {
                  query.userName = userNameFilter
                  query.page = 1
                  loadData()
                  confirm?.()
                }}
              >
                确定
              </Button>
            </div>
          </div>
        )
      }
    },
    {
      title: <strong>创建时间</strong>,
      key: 'createTime',
      dataIndex: 'createTime',
      width: 180,
      sorter: true,
      render: (text: string) => {
        return moment(text).format('YYYY-MM-DD HH:mm')
      }
    },
    {
      title: <strong>更新时间</strong>,
      key: 'updateTime',
      dataIndex: 'updateTime',
      width: 180,
      sorter: true,
      render: (text: string) => {
        return moment(text).format('YYYY-MM-DD HH:mm')
      }
    }
  ]


  // 过滤  排序
  const changeTable = (pager: any, filter: any, sorter: any) => {
    const { order, field } = sorter

    let ope = '0'
    if (order === 'ascend') {
      ope = '1'
    }
    if (order === 'descend') {
      ope = '2'
    }

    Object.keys(query).forEach((key) => {
      if (key.includes('Sort')) {
        query[key] = '0'
      }
    })

    query[`${field}Sort`] = ope

    loadData()
  }

  // 过滤  排序
  const changePage = (current, pageSize) => {
    query.page = current
    query.size = pageSize

    loadData()
  }

  return (
    <div>
      <Card
        title='工单列表'
      >
        <Table
          dataSource={dataSource}
          columns={columns}
          onChange={changeTable}
          scroll={{ x: '100%', y: 'calc(100vh - 320px)' }}
          pagination={false}
        />
        <div className={styles.paginationWrap}>
          <Pagination
            className="left-pagination"
            size="small"
            total={query.total}
            showTotal={(total) => {
              return `共 ${total} 条`
            }}
            showSizeChanger
            current={query.page}
            pageSize={query.size}
            defaultPageSize={20}
            pageSizeOptions={['10', '20', '50']}
            onChange={changePage}
            locale={{ items_per_page: '' }}
          />
          <Pagination
            size="small"
            total={query.total}
            current={query.page}
            defaultPageSize={20}
            pageSize={query.size}
            pageSizeOptions={['10', '20', '50']}
            showQuickJumper
            showSizeChanger={false}
            locale={{ jump_to: '前往' }}
            onChange={changePage}
          />
        </div>
      </Card>
    </div>
  )
}
