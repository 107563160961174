/** Generate by swagger-axios-codegen */
// @ts-nocheck
/* eslint-disable */

import { IRequestOptions, IRequestConfig, getConfigs, axios } from './serviceOptions';
export const basePath = '';

export interface IList<T> extends Array<T> {}
export interface List<T> extends Array<T> {}
export interface IDictionary<TValue> {
  [key: string]: TValue;
}
export interface Dictionary<TValue> extends IDictionary<TValue> {}

export interface IListResult<T> {
  items?: T[];
}

export class ListResultDto<T> implements IListResult<T> {
  items?: T[];
}

export interface IPagedResult<T> extends IListResult<T> {
  totalCount?: number;
  items?: T[];
}

export class PagedResultDto<T = any> implements IPagedResult<T> {
  totalCount?: number;
  items?: T[];
}

// customer definition
// empty

export class LoginService {
  /**
   * manage系统登陆
   */
  static postManageLogin(
    params: {
      /**  */
      root?: object;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<any> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/manage/login';

      const configs: IRequestConfig = getConfigs('post', 'application/json', url, options);

      let data = params['root'];

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
}

export class WorkOrderService {
  /**
   * 工单信息
   */
  static getManageWorkOrderInfo(
    params: {
      /** 工单编号 */
      orderNo?: string;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<any> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/manage/work_order/info';

      const configs: IRequestConfig = getConfigs('get', 'application/json', url, options);
      configs.params = { orderNo: params['orderNo'] };
      let data = null;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   * 工单列表
   */
  static getManageWorkOrderList(
    params: {
      /** 工单号筛选 */
      orderNo?: string;
      /** 描述 */
      orderDesc?: string;
      /** 响应状态 0:未回复（用户侧） 1:已回复（用户侧） */
      respondStatus?: string;
      /** 创建时间 1:asc 2:desc */
      createTimeSort?: string;
      /** 更新时间 1:asc 2:desc */
      updateTimeSort?: string;
      /**  */
      page?: string;
      /**  */
      size?: string;
      /** 类型1:业务咨询 2:使用帮助 3:问题反馈 4:其他  多选逗号分隔 */
      type?: string;
      /**  昵称 */
      userName?: string;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<any> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/manage/work_order/list';

      const configs: IRequestConfig = getConfigs('get', 'application/json', url, options);
      configs.params = {
        orderNo: params['orderNo'],
        orderDesc: params['orderDesc'],
        respondStatus: params['respondStatus'],
        createTimeSort: params['createTimeSort'],
        updateTimeSort: params['updateTimeSort'],
        page: params['page'],
        size: params['size'],
        type: params['type'],
        userName: params['userName']
      };
      let data = null;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   * 工单回复
   */
  static postManageWorkOrderReply(
    params: {
      /**  */
      root?: object;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<any> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/manage/work_order/reply';

      const configs: IRequestConfig = getConfigs('post', 'application/json', url, options);

      let data = params['root'];

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   * 工单文件上传
   */
  static postManageWorkOrderUploadFile(
    params: {
      /** 文件 */
      file?: any;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<any> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/manage/work_order/uploadFile';

      const configs: IRequestConfig = getConfigs('post', 'multipart/form-data', url, options);

      let data = null;
      data = new FormData();
      if (params['file']) {
        if (Object.prototype.toString.call(params['file']) === '[object Array]') {
          for (const item of params['file']) {
            data.append('file', item as any);
          }
        } else {
          data.append('file', params['file'] as any);
        }
      }

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   * 工单消息列表
   */
  static getManageWorkOrderMsgList(
    params: {
      /**  */
      orderNo?: string;
      /**  */
      page?: string;
      /**  */
      size?: string;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<any> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/manage/work_order/msg_list';

      const configs: IRequestConfig = getConfigs('get', 'application/json', url, options);
      configs.params = { orderNo: params['orderNo'], page: params['page'], size: params['size'] };
      let data = null;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
}
