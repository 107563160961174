import { HashRouter as Router, useRoutes } from 'react-router-dom'
import ReactDOM from 'react-dom'
import { ConfigProvider, message } from 'antd'
import React from 'react'
import zh_CN from 'antd/lib/locale-provider/zh_CN'
import './index.less'
import { serviceOptions } from './apis/serviceOptions'
import axios from 'axios'
import qs from 'qs'
import { token } from './utils/utils'
import { Global } from './global'
import { workOrderRouter } from './router'

const AppRouter = () => {
  const element = useRoutes(workOrderRouter)
  return element
}

let antd_lang = zh_CN


export function init() {
  serviceOptions.axios = axios


  axios.defaults.paramsSerializer = (params: any) => {
    return qs.stringify(params, { arrayFormat: 'brackets' })
  }

  axios.interceptors.request.use((config) => {
    if (config.method?.toUpperCase() === 'GET' && config.headers) {
      config.headers['Content-Type'] = 'application/json'
    }
    if (config.method?.toUpperCase() === 'DELETE' && config.headers) {
      config.headers['Content-Type'] = ''
    }
    if (config?.headers) {
      config.headers['Authorization'] = token.get() || ''
      config.headers['tenantId'] = Global?.user?.tenantId || ''
    }
    return config
  })

  // 初始化拦截器 拦截未登录请求
  axios.interceptors.response.use((response) => {
    if (response.data.code === 10005 || response.data.code === 50003 || response.data.code === 50004) {
      return Promise.resolve(response)
    } else if (response.data.code === 10002) {
      if (token.get()) {
        message.error('需要登录').then()
      }
      Global.user = {}
      token.clear()
      if (location.href.includes('register')) {
        location.assign('#/login/register')
      } else {
        location.assign('#/login/index')
      }

      return Promise.reject(response)
    } else if (response.data.code !== 200) {
      try {
        if (response.config.baseURL?.includes('mock')) {
          return Promise.resolve(response)
        }
        message.error(response?.data?.msg).then()
        return Promise.reject(response?.data?.msg)
      } catch (e) {
        console.log(e)
      }
    } else {
      return Promise.resolve(response)
    }
  })
}

if (location.href.includes('localhost')) {
  // axios.defaults.baseURL = 'http://121.229.30.34:31980'
  // axios.defaults.baseURL = 'http://klf-uat.i3060.com'
  // axios.defaults.baseURL = 'http://klf-dev.i3060.com'
  axios.defaults.baseURL = 'http://klf-wh-dev-manage.i3060.com'
  // axios.defaults.baseURL = 'https://service.wongy.cn/api/klf'
}


init()

ReactDOM.render(
  <ConfigProvider locale={antd_lang}>
    <Router>
      <AppRouter />
    </Router>
  </ConfigProvider>,
  document.getElementById('root')
)
